<template>
  <content-wrapper :title="labels.LBL_PROJECTS" back>
    <template #actions>
      <el-button size="small" type="success" @click="submitForm('projectForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_SAVE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      :rules="rules"
      ref="projectForm"
      :model="project"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item prop="name" :label="labels.LBL_NAME">
            <el-input v-model="project.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Installation Date">
            <el-input v-model="project.installation_date"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="description" label="Description">
            <el-input v-model="project.description" type="textarea" rows="4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Reason">
            <el-input v-model="project.reason" type="textarea" rows="4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Action">
            <el-input v-model="project.action" type="textarea" rows="4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Result">
            <el-input v-model="project.result" type="textarea" rows="4"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";

  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import { mapState } from "vuex";

  export default {
    name: "AddProject",

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,
        rules,

        project: {
          name: "",
          description: "",
          installation_date: "",
          reason: "",
          action: "",
          result: "",
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
      }),
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("project/createProject", this.project);
          }
        });
      },
    },
  };
</script>

<style></style>
